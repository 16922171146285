.App {
  min-height: 100vh;
}
.createpostdiv {
  background-color: #f0f2f5;
}
.createpostdiv:hover {
  background-color: #dcdfe2;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.link-style {
  cursor: pointer;
}
.link-style:hover {
  text-decoration: underline;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.dpm {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
}
.dpm-header {
  font-weight: 700;
}

.dpm-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.7rem;
}
.unclickablevalue {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.searchaccounts {
  background-color: white;
}
.searchaccounts:hover {
  cursor: pointer;
  background-color: #e3e0e0;
}
.createpostmodalradius {
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
.createpostmodalradiusdark {
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
.hoveru:hover {
  text-decoration: underline;
}

.addPointer {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .createpostmodalradius {
    border-radius: 0px;
  }
  .createpostmodalradiusdark {
    border-radius: 0px;
  }
}
.image-uploader {
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px dashed gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-text {
  color: gray;
  font-size: 18px;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.heartbeat-icon {
  animation: heartbeat 1s infinite alternate, tilt 1s infinite alternate;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes tilt {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

/* post image container  */
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex-grow: 1;
}

/* For one item */
.flex-container .flex-item:nth-child(1) {
  width: 100%;
}

/* For two items */
.flex-container .flex-item:nth-child(2n) {
  width: 50%;
}

/* For three items */
.flex-container .flex-item:nth-child(3n-2) {
  width: 100%;
}

.flex-container .flex-item:nth-child(4n-3),
.flex-container .flex-item:nth-child(4n-2),
.flex-container .flex-item:nth-child(4n-1),
.flex-container .flex-item:nth-child(4n) {
  width: 50%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Adjust opacity here */
  z-index: 999;
  /* Adjust z-index as needed */
}
